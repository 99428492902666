import { useState, useEffect } from "react";

interface Constraints {
  top: number;
  bottom: number;
}

export function useScrollConstraints(ref: React.RefObject<HTMLElement>, measureConstraints: boolean): Constraints {
  const [constraints, setConstraints] = useState<Constraints>({
    top: 0,
    bottom: 0,
  });

  useEffect(() => {
    if (!measureConstraints || !ref.current) return;

    const element = ref.current;
    const viewportHeight = window.innerHeight;
    const contentTop = element.offsetTop;
    const contentHeight = element.offsetHeight;
    const scrollableViewport = viewportHeight - contentTop * 2;
    const top = Math.min(scrollableViewport - contentHeight, 0);
    const bottom = 0; // If you need more logic for bottom, adjust here

    setConstraints({ top, bottom });
  }, [measureConstraints, ref]);

  return constraints;
}
