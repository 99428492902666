// components/Contact.js
import React, { useState, useEffect } from 'react';
import { motion, useAnimate, useInView } from 'framer-motion';
import './Contact.css';
const Contact = () => {
    const [isHovered, setIsHovered] = useState(false);
    const [scope, animate] = useAnimate()
    const isInView = useInView(scope)

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const icon = {
        hidden: {
            opacity: 0,
            pathLength: 0,
        },
        visible: {
            opacity: 1,
            pathLength: 1,
            transition: {
                pathLength: { type: "spring", duration: 1.5, bounce: 0 },
                opacity: { duration: 0.1 }
            }
        }
    }
    useEffect(() => {
        if (isInView) {
            animate(scope.current, { opacity: 1 })
        }
    }, [isInView, animate, scope])

    const icon2 = {
        //"bg-darkslate-500 text-white p-6 rounded-lg border border-darkslate-100",
        inactive: {
            backgroundColor: "rgb(23 23 23)",
            color: "rgb(255 255 255)",
            padding: "1.5rem",
            borderRadius: ".5rem",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: ["rgb(44 44 44)"],
        },
        //"hover:bg-darkslate-500 text-white p-6 rounded-lg border hover:border-primary-500"
        active: {
            backgroundColor: "rgb(23 23 23)",
            color: "rgb(255 255 255)",
            padding: "1.5rem",
            borderRadius: ".5rem",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: [null, "rgb(230 0 0)"],

        }
    }


    const handleLinkClick = (link) => {

        window.open(link.link, '_blank');

    }

    return (
        <motion.div
            animate={isHovered ? "active" : "inactive"}
            variants={icon2}
            initial="inactive"
            onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
            transition={{ duration: 0.25 }}
            className='contact-section'
            onFocus={e => e.target.blur()}>
            <section className="test">
                <h2 className="text-xl font-bold">Get in touch!</h2>
                <address className="font-light">
                    <p>Email: <a href="mailto:contact@bevanneib.com" className="text-primary-500 hover:text-primary-500/30"
                        target="mailto:contact@bevanneib.com"
                    >Contact@bevanneib.com</a>
                    </p>
                    <p>Location: Rochester, NY</p>
                    <div className="mt-4">
                        <h3 className="text-gray-500">Socials</h3>
                        <ul className="flex gap-4" style={{ fill: "white" }}>
                            <li>
                                <component className="text-primary-500 hover:text-primary-500/30" onClick={() => handleLinkClick({
                                    link: "https://github.com/happihound"
                                })}>
                                    <motion.svg aria-hidden="true" height="24" version="1.1" viewBox="0 0 16 16" width="24" className="item"
                                        whileHover={{ scale: [null, 1.5, 1.4] }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <motion.path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0 0 16 8c0-4.42-3.58-8-8-8z"
                                            variants={icon}
                                            initial={{ pathLength: 0 }}
                                            animate="visible"
                                            transition={{
                                                default: { duration: 2, ease: "easeInOut" },
                                                fill: { duration: 2, ease: [null, 0, 0.8, 1] }
                                            }}
                                        />
                                    </motion.svg>
                                </component>
                            </li>
                            <li>
                                <component className="text-primary-500 hover:text-primary-500/30" onClick={() => handleLinkClick({ link: "https://www.linkedin.com/in/bevan-neiberg" })}>
                                    <motion.svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="item"
                                        whileHover={{ scale: [null, 1.5, 1.4] }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <motion.path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                                            variants={icon}
                                            initial={{ pathLength: 0 }}
                                            animate="visible"
                                            transition={{
                                                default: { duration: 2, ease: "easeInOut" },
                                                fill: { duration: 2, ease: [null, 0, 0.8, 1] }
                                            }}
                                        />

                                    </motion.svg>
                                </component>
                            </li>
                        </ul>
                    </div>
                </address>
            </section>
        </motion.div>
    );
};

export default Contact;
